import React, { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  //   const [user, setUser] = useState(null);
  const [token, setToken] = useState(
    localStorage.getItem("jwtToken") ? localStorage.getItem("jwtToken") : ""
  );
  const [image, setImage] = useState(
    localStorage.getItem("profileImage") ? localStorage.getItem("profileImage") : ""
  );

  const [user, setUser] = useState(
    localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : ""
  );

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        image,
        setImage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
